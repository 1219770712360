.blog-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin-bottom: 50px;
}
@media (max-width:767px) {
  .blog-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
}
.blog-content .blog-box {
  margin: 0 auto;
}
.blog-content img {
  width: 100%;
  border-radius: 20px;
}
.blog-content h2 {
  font-size: 28px;
  margin: 10px 0;
  color: #111;
}
.blog-content h5 {
  color: #aaa;
  margin-bottom: 20px;
}
.blog-content p {
  color: #777;
  line-height: 1.6;
  font-size: 16px;
}
