.paytabs-form {
  max-width: 550px;
  margin: 50px auto;
  padding: 40px;
  background: linear-gradient(135deg, #ffffff, #f0f0f0);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.paytabs-form:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.paytabs-form h2 {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.paytabs-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.paytabs-form input {
  padding: 15px;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #f7f7f7;
  font-size: 16px;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
}

.paytabs-form input:focus {
  border-color: #007bff;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
  outline: none;
}

.paytabs-form input::placeholder {
  color: #999;
}

.paytabs-form button {
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.paytabs-form button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.paytabs-form button:hover:not(:disabled) {
  background-color: #0056b3;
  box-shadow: 0 5px 15px rgba(0, 91, 179, 0.4);
}

.paytabs-form .error {
  color: #e74c3c;
  font-size: 14px;
  text-align: center;
  margin-top: -10px;
}

@media (max-width: 600px) {
  .paytabs-form {
    padding: 30px;
  }

  .paytabs-form h2 {
    font-size: 22px;
  }

  .paytabs-form button {
    font-size: 16px;
  }
}
