:root {
  --black-color: #282828;
  --mobs-color: #9b907e;
  --sand-color: #ddd9ce;
  --bacground-color: #f4f0e7;
  --soft-cloud-color: #fffefd;
  --text-color: #777;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 576px) {
  .container {
    width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1350px;
  }
}

main {
  background-color: var(--bacground-color);
}
button {
  font-family: "Cairo";
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
p {
  text-transform: capitalize;
}
.admin {
  font-family: "Cairo", sans-serif !important;
  padding: 10px;
  width: 100%;
  flex: 1 1 80%;
}
.admin-content {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}
.product table {
  width: 90%;
  margin: auto;
  padding-top: 30px;
  text-align: center !important;
}
.product {
  flex: 1 1 80%;
  padding: 20px;
}
.product table thead td {
  color: #77777761;
  border-bottom: 1px solid #77777738;
  padding-bottom: 20px;
}
.product table tbody td {
  padding: 20px;
  margin: 15px;
}
.product table tbody td h4 {
  font-weight: 400;
  background: #ed2a2a;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s;
}
.product table tbody td h3 {
  font-weight: 400;
  background: #2196f3;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s;
}
.product table tbody td h4:hover,
.product tbody h3:hover {
  transform: scale(1.03) rotate(1deg);
}
@media (max-width: 991px) {
  .product {
    width: 100% !important;
    margin: 0px !important;
    overflow-x: scroll;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    font-size: 34px !important;
  }
}
.flipsideanimtion {
  direction: "ltr" !important;
}

.swiper-button-next,.swiper-button-prev {
  color: var(--mobs-color) !important;
}
.swiper-pagination-bullet-active {
  background: var(--mobs-color) !important;
}
.postblog {
  padding-top: 50px;
  padding-bottom: 50px;
}
.postblog-img img {
  width: 70%; 
  border-radius: 20px;
}
.postblog-text h2 {
  font-size: 38px;
}
.postblog-text h5 {
  color: #77777738;
}
.postblog-text p {
  line-height: 2;
  color: #777;
  white-space: pre-wrap;
}
