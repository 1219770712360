.session-box {
    padding: 30px 0;
    display: flex;
    align-items: center;
    gap: 50px;
    margin: auto;
    justify-content: center;
}
@media (max-width:767px) {
.session-box {
    flex-direction: column;
}
}
.session-text h2 {
    font-size: 38px;
}
.session-text h5 {
font-size: 13px;
margin-bottom: 30px;
}
.session-text p {
    line-height: 2;
    margin-bottom: 20px;
}
.session-text a {
    padding: 8px 20px ;
    border-radius: 8px;
    background: white;
    color: black;
    font-size: 22px;
}
.session-img {
    width: 360px;
}
.session-img img {
    max-width:100%;
    border-radius: 20px;
}